import { useRouter } from 'next/dist/client/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { configsSelector } from '../../store/feature/configsSlice';
import {
  currencies,
  listingCategories,
  myAccountListingDetails,
  storeSelector,
} from '../../store/feature/storeSlice';
import EditProductForm from '../../components/MyStore/EditProduct/EditProductForm';
import EditSchedulePart from '../../components/MyStore/EditProduct/schedule/EditSchedulePart';
import EditVariantsPart from '../../components/MyStore/EditProduct/Variants/EditVariantsPart';
import { getDigitalContent } from '../../store/feature/digital content/digitalContentSlice';
import DigitalProduct from '../../components/MyStore/EditProduct/DigitalProduct/DigitalProduct';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { useState } from 'react';
import EditPricingRules from '../../components/AddProduct/PricingRules/EditPricingRules';

const EditProductPageLayout = () => {
  const { auth_key } = useSelector(authSelector);
  const router = useRouter();
  const productId = router.query.product_id;
  const [order_type, setOrderType] = useState('listings');

  const { listing_configs, my_account_listing_details } =
    useSelector(storeSelector);
  const { genral_configs, MARKETPLACE_MODULES, MARKETPLACE_FLAVOURS } =
    useSelector(configsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currencies());
    dispatch(
      listingCategories({
        prams: { parent: 0, type: 'listings' },
      })
    );
    dispatch(myAccountListingDetails({ slug: productId, id: true }));
    dispatch(getDigitalContent({ listing_id: productId }));
  }, []);
  return (
    <div className="  w-full pt-2  pb-20 flex items-center c-lg:items-start  flex-col gap-8 md:gap-2   c-lg:flex-row justify-center">
      <div className="w-full  c-lg:w-[60%]  flex flex-col gap-8">
        <div className=" bg-white  w-full  p-5 shadow-c-sm rounded-card  ">
          {listing_configs !== null && (
            <EditProductForm
              order_type={order_type}
              setOrderType={setOrderType}
            />
          )}
        </div>

        <div className="    w-full   ">
          {listing_configs !== null &&
            order_type !== 'information_listing' &&
            order_type !== 'video_listing' && (
              <EditVariantsPart order_type={order_type} />
            )}
        </div>
      </div>
      {(MARKETPLACE_MODULES == 2 ||
        my_account_listing_details?.order_type === 'events' ||
        my_account_listing_details?.order_type === 'appointments' ||
        my_account_listing_details?.order_type === 'digital' ||
        order_type === 'events' ||
        order_type === 'appointments' ||
        order_type === 'digital') && (
        <div className="     c-lg:mt-0   ltr:c-lg:ml-[20px] rtl:c-lg:mr-[20px] w-full   c-lg:w-[30%]  c-lg:flex flex-col gap-8">
          {(MARKETPLACE_MODULES == 2 ||
            my_account_listing_details?.order_type === 'events' ||
            my_account_listing_details?.order_type === 'appointments' ||
            order_type === 'events' ||
            order_type === 'appointments' ||
            order_type === 'information_listing' ||
            order_type === 'video_listing') && (
            <>
              <EditSchedulePart />
              {my_account_listing_details && (
                <EditPricingRules
                  variantsArray={my_account_listing_details?.variants}
                  schedulesArray={my_account_listing_details?.schedules}
                  listing_id={my_account_listing_details?.id}
                />
              )}
            </>
          )}
          {(my_account_listing_details?.order_type === 'digital' ||
            order_type === 'digital') && <DigitalProduct />}
        </div>
      )}
    </div>
  );
};

export default EditProductPageLayout;
